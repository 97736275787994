<template>
  <div class="d-flex align-items-center">
    <v-dialog transition="slide-y-transition" v-model="row.item.show_detail" max-width="700px"
      v-if="row.item.assignedShifts.length > 0 && row.item.timesheet.clockInTimestamp !== null">
      <template v-slot:activator="{ on }">
        <v-btn depressed small block color="grey lighten-4" v-on="on" @click.stop.prevent="">
          {{ getShiftTime(row.item.timesheet.shift) }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="grey lighten-4">
          <v-list-item-content>
            <v-list-item-title class="title">Vardiya Detayı</v-list-item-title>
          </v-list-item-content>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="grey lighten-2" icon @click="row.item.show_detail = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("buttons.close") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-subheader style="minHeight: 90px !important">
            <v-divider inset style="margin-right: 25px !important" />
            <div class="d-flex" style="flex-direction: column">
              <div class="headline text-xs-center">{{ $helpers.formatDate(row.item.date) }}</div>
              <div class="mt-1 title text-xs-center" v-if="row.item.timesheet.shift.shiftType !== 'OFF_DAY'">
                {{ row.item.timesheet.shift.name }}
              </div>
              <div class="mt-1 title text-xs-center"
                v-if="row.item.timesheet.shift.shiftType === 'OFF_DAY' && !row.item.countAsOvertime && !row.item.breakDay">
                Hafta Tatili
              </div>
              <div class="mt-1 title text-xs-center"
                v-if="row.item.timesheet.shift.shiftType === 'OFF_DAY' && !row.item.countAsOvertime && row.item.breakDay">
                Dinlenme Günü
              </div>
            </div>
            <v-divider inset style="margin-left: 25px !important" />
          </v-subheader>
          <div class="body-1 text-xs-center mt-3" v-if="row.item.countAsOvertime">
            Çalışan bu tarihte işe gelirse fazla mesai olarak hesaplanır
          </div>
          <div class="body-1 text-xs-center mt-3"
            v-if="row.item.timesheet.shift.shiftType === 'OFF_DAY' && !row.item.countAsOvertime && !row.item.breakDay">
            Çalışanın haftalık tatilidir
          </div>
          <div class="body-1 text-xs-center mt-3"
            v-if="row.item.timesheet.shift.shiftType === 'OFF_DAY' && row.item.breakDay">
            Çalışanın dinlenme günüdür
          </div>
          <v-layout row wrap v-if="row.item.timesheet.shift.shiftType !== 'OFF_DAY' && !row.item.countAsOvertime">
            <template v-if="row.item.timesheet.shift.shiftType === 'FIX'">
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="35" color="teal" left>tw-in</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Başlangıç Saati</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.beginTime }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="35" color="red" left>tw-out</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Bitiş Saati</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.endTime }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
            <template>
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="brown" left>mdi-coffee</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Mola Süresi</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $helpers.convertMinutesToTime(row.item.timesheet.shift.totalBreakTimeInMinutes)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="blue" left>mdi-update</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Vardiya Süresi</v-list-item-title>
                      <v-list-item-subtitle>{{
                        $helpers.convertMinutesToTime(row.item.timesheet.shift.totalWorkingTimeInMinutes)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
            <template v-if="row.item.overtimes.length">
              <v-flex xs12>
                <v-divider />
              </v-flex>
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="red" left>tw-access-pass-plan</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Fazla Mesai</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.overtimes[0].overtimeInMinutes }} {{ $t("global.minute") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" color="red" left>tw-access-pass-plan</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(`time.overtime_request.periods.${row.item.overtimes[0].period}`)
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t(`leave_request_status.${row.item.overtimes[0].requestStatus}`)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
            <template v-if="row.item.timesheet.shift.shiftType === 'FIX'">
              <v-flex xs12>
                <v-divider />
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.maxClockInOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-end</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.shift.max_clock_in_overtime_in_minutes") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.maxClockInOvertimeInMinutes }} {{
                        $t("global.minute") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.minClockInOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-end</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.shift.min_clock_in_overtime_in_minutes") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.minClockInOvertimeInMinutes }} {{
                        $t("global.minute") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
            <template
              v-if="row.item.timesheet.shift.shiftType === 'FIX' || row.item.timesheet.shift.shiftType === 'DYNAMIC'">
              <v-flex xs12>
                <v-divider />
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.maxClockOutOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-start</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.max_clock_out_overtime_in_minutes") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.maxClockOutOvertimeInMinutes }} {{
                        $t("global.minute") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.minClockOutOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-start</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.min_clock_out_overtime_in_minutes") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.minClockOutOvertimeInMinutes }} {{
                        $t("global.minute") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs12>
                <v-divider />
              </v-flex>
              <v-flex xs6
                v-if="row.item.timesheet.shift.shiftType === 'FIX' && !$helpers.isUndef(row.item.timesheet.shift.latelyClockInTolerance)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-in</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.lately_clock_in_tolerance") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.latelyClockInTolerance }} {{ $t("global.minute")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.earlyClockOutTolerance)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-out</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.early_clock_out_tolerance") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.earlyClockOutTolerance }} {{ $t("global.minute")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6
                v-if="row.item.timesheet.shift.shiftType === 'DYNAMIC' && !$helpers.isUndef(row.item.timesheet.shift.midnightTolerance)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-out</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.midnight_tolerance") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.midnightTolerance }} {{ $t("global.minute")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
            <template v-if="row.item.timesheet.shift.shiftType === 'FLEXIBLE'">
              <v-flex xs12>
                <v-divider />
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.maxOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-end</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.maximum_overtime") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.maxOvertimeInMinutes }} {{ $t("global.minute")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
              <v-flex xs6 v-if="!$helpers.isUndef(row.item.timesheet.shift.minOvertimeInMinutes)">
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="30" left>mdi-clock-end</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("time.calendar.minimum_overtime") }}</v-list-item-title>
                      <v-list-item-subtitle>{{ row.item.timesheet.shift.minOvertimeInMinutes }} {{ $t("global.minute")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn label dark block v-else-if="row.item.holiday.name && row.item.countAsOvertime" depressed small
      :color="row.item.holiday.color">
      {{ row.item.holiday.name }}
    </v-btn>
    <v-btn label text-color="black" block v-else-if="row.item.leaveRequest.id" depressed small
      :color="row.item.leaveRequest.leaveType.color">
      {{ row.item.leaveRequest.leaveType.name }}
    </v-btn>
    <v-btn label text-color="black" block
      v-else-if="row.item.assignedShifts.length === 0 && !row.item.timesheet.id && !row.item.countAsOvertime && row.item.breakDay"
      depressed small color="grey lighten-2">
      {{ $t("shift.break_day") }}
    </v-btn>
    <v-btn label text-color="black" block
      v-else-if="row.item.assignedShifts.length === 0 && !row.item.timesheet.id && !row.item.countAsOvertime" depressed
      small color="grey lighten-2">
      {{ $t("shift.off_day") }}
    </v-btn>
    <v-btn label text-color="black" block v-else-if="row.item.timesheet.id" depressed small color="grey lighten-4">
      {{ row.item.timesheet.shift.time }}
    </v-btn>
    <v-btn label text-color="black" block v-else-if="isShiftOvertime && !row.item.countAsOvertime" depressed small
      color="grey lighten-2">
      {{ $t("shift.off_day") }}
    </v-btn>
    <v-btn label v-else-if="isAbsent" block text-color="white" dark depressed small color="red lighten-3">
      {{ $t("shift.absent") }}
    </v-btn>
    <v-btn label v-else block text-color="white" depressed small color="grey lighten-4"> - </v-btn>
  </div>
</template>

<script>
import { SHIFT_TYPES } from "@/helpers/enums";

export default {
  name: "ShiftDetail",
  props: {
    row: Object
  },
  data: () => ({}),
  methods: {},
  computed: {
    isShiftOvertime() {
      return this.row.item.assignedShifts.find(item => item.shiftType === SHIFT_TYPES.OVERTIME);
    },
    isAbsent() {
      const { row } = this;
      const assignedShift = row.item.assignedShifts[0];
      let isAbsent = true;

      if (assignedShift && this.$moment().format("YYYY-MM-DD") === row.item.date) {
        const date = this.$moment(assignedShift.beginTime, "HH:mm").add(assignedShift.latelyClockInTolerance, "minutes");
        isAbsent = date.isBefore(this.$moment());
      }

      return (
        row.item.assignedShifts.length > 0 &&
        !row.item.timesheet.clockInTimestamp &&
        this.$moment(row.item.date).isSameOrBefore(this.$moment().format("YYYY-MM-DD")) &&
        !row.item.countAsOvertime &&
        (this.$moment(row.item.date).isBefore(this.$moment().format("YYYY-MM-DD")) ||
          !row.item.assignedShifts.some(item => item.shiftType === "FLEXIBLE" || item.shiftType === "DYNAMIC")) &&
        isAbsent
      );
    },
    isAbsent2() {
      const workplan = this.row.item;
      const today = this.$moment().format("YYYY-MM-DD");
      const workplanDateObject = this.$moment(workplan.date);
      const hasAssignedShift = workplan.assignedShifts.length > 0;
      const hasClockInTimestamp = workplan.timesheet.clockInTimestamp;
      const isSameOrBeforeToday = workplanDateObject.isSameOrBefore(today);
      const countAsOvertime = workplan.countAsOvertime;
      const hasFlexibleOrDynamicShiftToday =
        workplanDateObject.isSame(today) && workplan.assignedShifts.some(({ shiftType }) => ["FLEXIBLE", "DYNAMIC"].includes(shiftType));
      const assignedShift = hasAssignedShift ? workplan.assignedShifts[0] : null;
    }
  },
  methods: {
    getShiftTime(item) {
      if (item.time === "null-null") {
        return this.$helpers.convertMinutesToTime(item.lengthInMinutes);
      } else return item.time;
    }
  }
};
</script>
